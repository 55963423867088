import { Button, Card, Grid, Icon, IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as modalActions from "./modalActionTypes";
import React, { useEffect, useState } from "react";
// Data
import shareholdingsTableData from "layouts/shareholdings/data/shareholdingsTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./redux/state/ShareholdingsAction";
import ShareholdingModal from "./components/ShareholdingModal";
import ConfirmDeleteShareholding from "./components/ConfirmDeleteShareholding";
import FormAddShareholding from "./components/FormAddShareholding";
import FormUpdateShareholding from "./components/FormUpdateShareholding";
import { Link, useLocation } from "react-router-dom";
import tabs from "layouts/rapport/tabs";
import { useMaterialUIController, setLayout } from "context";
import FormUploadShareholding from "./components/FormUploadShareholding";

function Shareholdings() {
  const location = useLocation();
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, location?.state?.id ? "page" : "dashboard");
  }, [pathname]);
  const [open, setOpen] = useState(false);
  const [shareholdingId, setShareholdingId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setShareholdingId("");
    setOpen(true);
  };
  const handleUpload = () => {
    setAction(modalActions.UPLOAD);
    setShareholdingId("");
    setOpen(true);
  };
  // const dispatchRedux = useDispatch();

  // let data = {};
  // useEffect(() => {
  //   if (location?.state?.id) {
  //     data = { companyId: location?.state?.id };
  //   }
  //   dispatchRedux(actions.getShareholdings(data));
  // }, []);
  // const data = useSelector((state) => state?.shareholdings?.shareholdings);

  const { columns, rows } = shareholdingsTableData(setOpen, setShareholdingId, setAction);

    return (
      <DashboardLayout hide={location?.state?.id}>
        <DashboardNavbar />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {location?.state?.id ? (
              <Typography color="info" variant="body2" fontWeight="bold">
                <Button
                  size="small"
                  component={Link}
                  to={`/rapport/${location?.state?.slug}`}
                  state={{
                    rapportTab: tabs.find((tab) => tab.key == "structure-actionnariat").index,
                  }}
                >
                  <Icon fontSize="large">arrow_back</Icon> Retour a la page rapport
                </Button>
              </Typography>
            ) : (
              ""
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box mr={1}>
              <MDButton onClick={handleOpen} to="#" variant="contained" size="small" color="info">
                <Icon>add</Icon> actionnariat
              </MDButton>
            </Box>
            {location?.state?.id ? (
            <Box mr={1}>
              <MDButton to="#" variant="contained" size="small" color="success" onClick={handleUpload}>
                Importer <Icon>upload</Icon>
              </MDButton>
            </Box>
            ):""}
          </Box>
        </Box>

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Actionnariat
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {rows && 
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ShareholdingModal
          open={open}
          setOpen={setOpen}
          title={
            action == modalActions.UPDATE
              ? "Modifier"
              : action == modalActions.DELETE
              ? "Supprimer"
              : "Ajouter"
          }
        >
          {/* ADD MODAL */}
          {action == modalActions.ADD && (
            <FormAddShareholding
              setOpen={setOpen}
              setAction={setAction}
              setShareholdingId={setShareholdingId}
            />
          )}

          {/* UPDATE MODAL */}
          {action == modalActions.UPDATE && (
            <FormUpdateShareholding
              setOpen={setOpen}
              setAction={setAction}
              setShareholdingId={setShareholdingId}
              shareholdingId={shareholdingId}
            />
          )}

          {/* DELETE MODAL */}
          {action == modalActions.DELETE && (
            <ConfirmDeleteShareholding
              setOpen={setOpen}
              setAction={setAction}
              setShareholdingId={setShareholdingId}
              shareholdingId={shareholdingId}
            />
          )}

          {/* UPLOAD MODAL */}
          {action == modalActions.UPLOAD && (
            <FormUploadShareholding
              setOpen={setOpen}
              setAction={setAction}
              setShareholdingId={setShareholdingId}
              shareholdingId={shareholdingId}
              companyId={location?.state?.id}
            />
          )}
        </ShareholdingModal>
      </DashboardLayout>
    );
}
export default Shareholdings;
