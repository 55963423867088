import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/state/CompaniesAction";
import * as actionsCountries from "../../countries/redux/state/CountriesAction";
import * as actionsProductServices from "../../productServices/redux/state/ProductServicesAction";
import { Link } from "react-router-dom";
import directorTemplateFile from "../../../../src/assets/excel/templates/entreprises_template.xlsx";
import { uploadCompanySchema } from "../schema";

export default function FormUploadCompany({ setOpen }) {

  
  const dispatch = useDispatch();
  
  const countries = useSelector((state) => state?.countries.countries);
  const activities = useSelector((state) => state?.productServices.productServices);
  // order select companies
  const orderCompanies = {
    sort: "raison_social",
    order: "asc",
  };
  const onSubmit = (values) => {
    console.log("values;;;;",values)
    const request = {
      payload: values,
      successCallBack: (response) => {
        dispatch(actions.getCompanies());
        setOpen(false);
        console.log("response", response);
      },
      failCallBack: (error) => {
        console.log("error", error);
      },
    };
    dispatch(actions.uploadCompany(request));
  };

  const orderCountries = {
    sort: "name",
    order: "asc",
  };
  // order select countries
  const orderProductServices = {
    sort: "name",
    order: "asc",
  };

  
  useEffect(() => {
    dispatch(actionsCountries.getCountries(orderCountries));
    dispatch(actionsProductServices.getProductServices(orderProductServices));
  }, []);

  const formik = useFormik({
    initialValues: {
      product_service_id: "",
      country_id: "1",
    },
    onSubmit,
    //validationSchema: uploadCompanySchema,
  });
  
  const { handleSubmit, errors, values, handleChange, setFieldValue } = formik;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox m={1}>
      <form onSubmit={handleSubmit}>
        <MDBox py={1}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Button variant="text">
              <Link to={directorTemplateFile} target="_blank" download="entreprises_template.xlsx">
                Télécharger un modèle d'importation de données
              </Link>
            </Button>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="country_id">Pays</InputLabel>
                <Select
                  name="country_id"
                  style={{ height: "40px" }}
                  labelId="state_label"
                  label="pays"
                  error={errors.country_id ? true : false}
                  onChange={handleChange}
                  value={values.country_id}
                >
                  {countries?.data?.map((country) => {
                    return (
                      <MenuItem key={country?.id} value={country?.id}>
                        {country?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.country_id ? (
                  <FormHelperText error>{errors?.country_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="product_service_id"
                  options={activities && activities.data ? activities?.data : []}
                  getOptionLabel={(option) => option.name}
                  //onChange={handleChange}
                  onChange={(e, newValue) => {
                    console.log("new value:::", newValue)
                    formik.setFieldValue(
                      "product_service_id",
                      newValue.id
                    );
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.product_service_id ? true : false}
                      name="product_service_id"
                      label="Activités"
                      placeholder="Activités"
                    />
                  )}
                />
                {errors.product_service_id ? (
                  <FormHelperText error>{errors?.product_service_id}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <InputLabel sx={{ padding: "5px 0px" }}>Importer un fichier :</InputLabel>
              <FormControl fullWidth>
                <MDInput
                  name="file"
                  type="file"
                  error={errors.file ? true : false}
                  onChange={(e) => {
                    formik.setFieldValue("file", e.target.files[0]);
                  }}
                  helperText={errors?.file ?? ""}
                />
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox sx={{ display: "flex" }}>
          <MDBox mr={1} sx={{ flexGrow: "1" }}>
            <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox sx={{ flexGrow: "1" }}>
            <MDButton type="submit" color="info" fullWidth>
              Importer
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}
