import {
    Autocomplete,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import MDBox from "components/MDBox";
  import MDButton from "components/MDButton";
  import MDInput from "components/MDInput";
  import { useFormik } from "formik";
  import React, { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import * as actions from "../redux/state/CompteDeResultatBilansAction";
  import { Link } from "react-router-dom";
  import directorTemplateFile from "../../../../../../src/assets/excel/templates/compte_de_resultat_bilans_template.xlsx";
  import { uploadCompanySchema } from "../schema";
  
  export default function FormUploadCompteDeResultatBilan({ setOpen,companyId }) {
  
    
    const dispatch = useDispatch();
    // order select companies
    const orderCompanies = {
      sort: "raison_social",
      order: "asc",
    };
    const onSubmit = (values) => {
      console.log("values;;;;",values)
      const request = {
        payload: values,
        successCallBack: (response) => {
          dispatch(actions.getCompteDeResultatBilans());
          setOpen(false);
          console.log("response", response);
        },
        failCallBack: (error) => {
          console.log("error", error);
        },
      };
      dispatch(actions.uploadCompteDeResultatBilan(request));
    };
  
    const orderCountries = {
      sort: "name",
      order: "asc",
    };
    // order select countries
    const orderProductServices = {
      sort: "name",
      order: "asc",
    };
  
    const formik = useFormik({
      initialValues: {
        company_id:companyId
      },
      onSubmit,
      //validationSchema: uploadCompanySchema,
    });
    
    const { handleSubmit, errors, values, handleChange, setFieldValue } = formik;
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <MDBox m={1}>
        <form onSubmit={handleSubmit}>
          <MDBox py={1}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Button variant="text">
                <Link to={directorTemplateFile} target="_blank" download="entreprises_template.xlsx">
                  Télécharger un modèle d'importation de données
                </Link>
              </Button>
  
              <Grid item xs={12} sm={12} md={12}>
                <InputLabel sx={{ padding: "5px 0px" }}>Importer un fichier :</InputLabel>
                <FormControl fullWidth>
                  <MDInput
                    name="file"
                    type="file"
                    error={errors.file ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue("file", e.target.files[0]);
                    }}
                    helperText={errors?.file ?? ""}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </MDBox>
  
          <MDBox sx={{ display: "flex" }}>
            <MDBox mr={1} sx={{ flexGrow: "1" }}>
              <MDButton onClick={handleClose} type="submit" color="primary" fullWidth>
                Annuler
              </MDButton>
            </MDBox>
            <MDBox sx={{ flexGrow: "1" }}>
              <MDButton type="submit" color="info" fullWidth>
                Importer
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </MDBox>
    );
  }
  