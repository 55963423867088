import { Card, FormControl, Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// Data
import compteResultatBilansTableData from "./data/compteResultatBilansTableData";
import DataTable from "examples/Tables/DataTable";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import * as modalActions from "./modalActionTypes";
import { useLocation, useParams } from "react-router-dom";
import FormAddCompteResultatBilan from "./components/FormAddCompteResultatBilan";
import CompteResultatBilanModal from "./components/DirectorModal";
import { useFormik } from "formik/dist";
import * as actionsCompanies from "../../../companies/redux/state/CompaniesAction";
import { useDispatch, useSelector } from "react-redux";
import FormUpdateCompteResultatBilan from "./components/FormUpdateCompteResultatBilan";
import ConfirmDeleteCompteResultatBilan from "./components/ConfirmDeleteCompteResultatBilan";
import styled from "@emotion/styled";
import FormUploadCompteResultatBilan from "./components/FormUploadCompteResultatBilan";
// import FormUpdateUser from "./components/FormUpdateUser";

const CompteResultatBilans = ({ companyId, setCompanyId }) => {
  const location = useLocation();
  const [compteResultatBilanId, setCompteResultatBilanId] = useState("");
  const [action, setAction] = useState(modalActions.ADD);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setAction(modalActions.ADD);
    setCompteResultatBilanId("");
    setOpen(true);
  };
  const handleUpload = () => {
    setAction(modalActions.UPLOAD);
    setCompteResultatBilanId("");
    setOpen(true);
  };
  const { columns, rows } = compteResultatBilansTableData(
    setOpen,
    setCompteResultatBilanId,
    setAction,
    companyId
  );

  const handleChange = (e) => {
    setCompanyId(e.target.value);
  };

  const companies = useSelector((state) => state?.companies.companies);

  console.log("companiessssss::::",companies)

  // Responsive Header Inputs styled components
  const HeaderInputs = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
  }));

  const FilterContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 250,
    },
  }));

  const ButtonsContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridGap:".4em"
      // gridAutoFlow: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }));

  const disabledSelect = location?.state?.id ? true: false;

  return (
    <Box m={2}>
      <HeaderInputs px={1}>
      <Box mr={1} >
          <FilterContainer>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filtre par entreprise</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="company_filter"
                value={companyId ?? ""}
                label="Filtre par entreprise"
                onChange={handleChange}
                sx={{ height: 40, background: "white" }}
                disabled = {disabledSelect}
              >
                <MenuItem value="">Selectionner une entreprise</MenuItem>
                {companies && companies.data && companies?.data?.map((company) => {
                  return (
                    <MenuItem key={company?.id} value={company?.id}>
                      {/* {company?.raison_social} */}
                      <p>
                        <b>{company?.raison_social}</b> ({company?.country?.name})
                      </p>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FilterContainer>
        </Box>
        <ButtonsContainer>
          <Box mr={1}>
            <MDButton fullWidth onClick={handleOpen} variant="contained" size="small" color="info">
              <Icon>add</Icon> Compte et Resultat Bilan
            </MDButton>
          </Box>
          <Box mr={1}>
            <MDButton fullWidth to="#" variant="contained" size="small" color="success" onClick={handleUpload}>
                Importer <Icon>uploadfile</Icon>
            </MDButton>
          </Box>
        </ButtonsContainer>
      </HeaderInputs>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Compte et Resultat Bilan
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  rows && 
                  <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CompteResultatBilanModal
        title={
          action == modalActions.UPDATE
            ? "Modifier Compte et resultat bilan"
            : action == modalActions.DELETE
            ? "Supprimer Compte et resultat bilan"
            : "Ajouter nouveau Compte et resultat bilan"
        }
        open={open}
        setOpen={setOpen}
      >
        {/* ADD MODAL */}
        {action == modalActions.ADD && (
          <FormAddCompteResultatBilan
            open={open}
            setOpen={setOpen}
            setAction={setAction}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        )}
        {/* DELETE MODAL */}
        {action == modalActions.DELETE && (
          <ConfirmDeleteCompteResultatBilan
            setOpen={setOpen}
            compteResultatBilanId={compteResultatBilanId}
            setCompteResultatBilanId={setCompteResultatBilanId}
            setAction={setAction}
            companyId={companyId}
          />
        )}
        {/* UPDATE MODAL */}
        {action == modalActions.UPDATE && (
          <FormUpdateCompteResultatBilan
            setOpen={setOpen}
            compteResultatBilanId={compteResultatBilanId}
            setCompteResultatBilanId={setCompteResultatBilanId}
            setAction={setAction}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        )}
        {action == modalActions.UPLOAD && (
          <FormUploadCompteResultatBilan
            setOpen={setOpen}
            setAction={setAction}
            compteResultatBilanId={compteResultatBilanId}
            setCompteResultatBilanId={setCompteResultatBilanId}
            setCompanyId={setCompanyId}
            companyId={companyId}
          />
        )}
      </CompteResultatBilanModal>
    </Box>
  );
};
export default CompteResultatBilans;
