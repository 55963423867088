import { useEffect } from "react";
import * as ActionsTypes from "../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  countryProfiles: [],
  countryProfile: {},
};

const CountryProfilesReducer = (state = initialState, action) => {
    switch (action.type) {
      // ##-----------GET_COUNTRY_PROFILES-----------##
      case ActionsTypes.GET_COUNTRY_PROFILES:
        return {
          ...state,
          isLoading: true,
          error: null,
          countryProfiles: [],
        };
      case ActionsTypes.GET_COUNTRY_PROFILES_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
          countryProfiles: action.response.data.data,
        };
      case ActionsTypes.GET_COUNTRY_PROFILES_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
          countryProfiles: [],
        };
      // ##-----------END_GET_COUNTRY_PROFILES-----------##

      // ##-----------ADD_COUNTRY_PROFILE-----------##
    case ActionsTypes.ADD_COUNTRY_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //countryProfiles: [...state.countryProfiles],
      };

    case ActionsTypes.ADD_COUNTRY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //countryProfiles: [ action.response.data.data, ...state.countryProfiles],
      };

    case ActionsTypes.ADD_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //countryProfiles: [...state.countryProfiles],
      };
    // ##-----------END_ADD_COUNTRY_PROFILE-----------##

    // ##-----------EDIT_COUNTRY_PROFILE-----------##
    case ActionsTypes.EDIT_COUNTRY_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        countryProfile: {},
      };

    case ActionsTypes.EDIT_COUNTRY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        countryProfile: action.response.data.data,
      };

    case ActionsTypes.EDIT_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        countryProfile: {},
      };
    // ##-----------END_EDIT_COUNTRY_PROFILE-----------##

    // ##-----------UPDATE_COUNTRY_PROFILE-----------##
    case ActionsTypes.UPDATE_COUNTRY_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //countryProfiles: [...state.countryProfiles],
      };

    case ActionsTypes.UPDATE_COUNTRY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // countryProfiles: [
        //   action.response.data.data,
        //   ...state.countryProfiles.filter((countryProfile) => countryProfile.id != action?.response?.data?.data?.id),
        // ],
      };

    case ActionsTypes.UPDATE_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //countryProfiles: [...state.countryProfiles],
      };
    // ##-----------END_UPDATE_COUNTRY_PROFILE-----------##
    
    
    // ##-----------UPDATE_COUNTRY_PROFILE-----------##
    case ActionsTypes.UPLOAD_COUNTRY_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //countryProfiles: [...state.countryProfiles],
      };

    case ActionsTypes.UPLOAD_COUNTRY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // countryProfiles: [
        //   action.response.data.data,
        //   ...state.countryProfiles.filter((countryProfile) => countryProfile.id != action?.response?.data?.data?.id),
        // ],
      };

    case ActionsTypes.UPLOAD_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //countryProfiles: [...state.countryProfiles],
      };
    // ##-----------END_UPDATE_COUNTRY_PROFILE-----------##

    // ##-----------DELETE_COUNTRY_PROFILE-----------##
    case ActionsTypes.DELETE_COUNTRY_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        //countryProfiles: [...state.countryProfiles],
      };

    case ActionsTypes.DELETE_COUNTRY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //countryProfiles: [...state.countryProfiles],
      };

    case ActionsTypes.DELETE_COUNTRY_PROFILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //countryProfiles: [...state.countryProfiles],
      };
    // ##-----------END_DELETE_COUNTRY_PROFILE-----------##
  
      default:
        return state;
    }
  };
  
  export default CountryProfilesReducer;