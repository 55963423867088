import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------GET_PASSIF_BILANS-----------##
export const getPassifBilans = (data) => {
  console.log("getPassifBilans", data);
  return { type: ActionsTypes.GET_PASSIF_BILANS, payload: data };
};

export const getPassifBilansSuccess = (response) => {
  return { type: ActionsTypes.GET_PASSIF_BILANS_SUCCESS, response };
};

export const getPassifBilansFail = (error) => {
  return { type: ActionsTypes.GET_PASSIF_BILANS_FAIL, error };
};
// ##-----------END_GET_PASSIF_BILANS-----------##

// ##-----------ADD_PASSIF_BILAN-----------##

export const addPassifBilan = (data) => {
  return { type: ActionsTypes.ADD_PASSIF_BILAN, payload: data };
};

export const addPassifBilanSuccess = (response) => {
  return { type: ActionsTypes.ADD_PASSIF_BILAN_SUCCESS, response };
};

export const addPassifBilanFail = (error) => {
  return { type: ActionsTypes.ADD_PASSIF_BILAN_FAIL, error };
};
// ##-----------END_ADD_PASSIF_BILAN-----------##

// ##-----------EDIT_PASSIF_BILAN-----------##
export const editPassifBilan = (data) => {
  return { type: ActionsTypes.EDIT_PASSIF_BILAN, payload: data };
};

export const editPassifBilanSuccess = (response) => {
  return { type: ActionsTypes.EDIT_PASSIF_BILAN_SUCCESS, response };
};

export const editPassifBilanFail = (error) => {
  return { type: ActionsTypes.EDIT_PASSIF_BILAN_FAIL, error };
};
// ##-----------END_EDIT_PASSIF_BILAN-----------##

// ##-----------UPDATE_PASSIF_BILAN-----------##
export const updatePassifBilan = (data) => {
  return { type: ActionsTypes.UPDATE_PASSIF_BILAN, payload: data };
};

export const updatePassifBilanSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_PASSIF_BILAN_SUCCESS, response };
};

export const updatePassifBilanFail = (error) => {
  return { type: ActionsTypes.UPDATE_PASSIF_BILAN_FAIL, error };
};
// ##-----------END_UPDATE_PASSIF_BILAN-----------##

// ##-----------DELETE_PASSIF_BILAN-----------##
export const deletePassifBilan = (data) => {
  return { type: ActionsTypes.DELETE_PASSIF_BILAN, payload: data };
};

export const deletePassifBilanSuccess = (response) => {
  return { type: ActionsTypes.DELETE_PASSIF_BILAN_SUCCESS, response };
};

export const deletePassifBilanFail = (error) => {
  return { type: ActionsTypes.DELETE_PASSIF_BILAN_FAIL, error };
};
// ##-----------END_DELETE_PASSIF_BILAN-----------##

// ##-----------UPLOAD_PASSIF_BILAN-----------##
export const uploadPassifBilan = (data) => {
  return { type: ActionsTypes.UPLOAD_PASSIF_BILAN, payload: data };
};

export const uploadPassifBilanSuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_PASSIF_BILAN_SUCCESS, response };
};

export const uploadPassifBilanFail = (error) => {
  return { type: ActionsTypes.UPLOAD_PASSIF_BILAN_FAIL, error };
};
// ##-----------END_UPLOAD_PASSIF_BILAN-----------##
