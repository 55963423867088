import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_PRODUCTSERVICES-----------##
export const getProductServices = (data = {}) => {
  return { type: ActionsTypes.GET_PRODUCTSERVICES, payload: data };
};


export const getProductServicesSuccess = (response) => {
  return { type: ActionsTypes.GET_PRODUCTSERVICES_SUCCESS, response };
};

export const getProductServicesFail = (error) => {
  return { type: ActionsTypes.GET_PRODUCTSERVICES_FAIL, error };
};

export const getAllProductServices = (data = {}) => {
  return { type: ActionsTypes.GET_ALL_PRODUCTSERVICES, payload: data };
};

export const getAllProductServicesSuccess = (response) => {
  return { type: ActionsTypes.GET_ALL_PRODUCTSERVICES_SUCCESS, response };
};

export const getAllProductServicesFail = (error) => {
  return { type: ActionsTypes.GET_ALL_PRODUCTSERVICES_FAIL, error };
};
// ##-----------END_GET_PRODUCTSERVICES-----------##

// ##-----------ADD_PRODUCTSERVICE-----------##
export const addProductService = (data) => {
  return { type: ActionsTypes.ADD_PRODUCTSERVICE, payload: data };
};

export const addProductServiceSuccess = (response) => {
  return { type: ActionsTypes.ADD_PRODUCTSERVICE_SUCCESS, response };
};

export const addProductServiceFail = (error) => {
  return { type: ActionsTypes.ADD_PRODUCTSERVICE_FAIL, error };
};
// ##-----------END_ADD_PRODUCTSERVICE-----------##

// ##-----------EDIT_PRODUCTSERVICE-----------##
export const editProductService = (data) => {
  return { type: ActionsTypes.EDIT_PRODUCTSERVICE, payload: data };
};

export const editProductServiceSuccess = (response) => {
  return { type: ActionsTypes.EDIT_PRODUCTSERVICE_SUCCESS, response };
};

export const editProductServiceFail = (error) => {
  return { type: ActionsTypes.EDIT_PRODUCTSERVICE_FAIL, error };
};
// ##-----------END_EDIT_PRODUCTSERVICE-----------##

// ##-----------UPDATE_PRODUCTSERVICE-----------##
export const updateProductService = (data) => {
  return { type: ActionsTypes.UPDATE_PRODUCTSERVICE, payload: data };
};

export const updateProductServiceSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_PRODUCTSERVICE_SUCCESS, response };
};

export const updateProductServiceFail = (error) => {
  return { type: ActionsTypes.UPDATE_PRODUCTSERVICE_FAIL, error };
};
// ##-----------END_UPDATE_PRODUCTSERVICE-----------##

// ##-----------DELETE_PRODUCTSERVICE-----------##
export const deleteProductService = (data) => {
  return { type: ActionsTypes.DELETE_PRODUCTSERVICE, payload: data };
};

export const deleteProductServiceSuccess = (response) => {
  return { type: ActionsTypes.DELETE_PRODUCTSERVICE_SUCCESS, response };
};

export const deleteProductServiceFail = (error) => {
  return { type: ActionsTypes.DELETE_PRODUCTSERVICE_FAIL, error };
};
// ##-----------END_DELETE_PRODUCTSERVICE-----------##
