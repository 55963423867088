import { useEffect } from "react";
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  compteResultatBilans: [],
  compteResultatBilan: {},
};

const compteResultatBilansReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_COMPTE_RESULTAT_BILANS-----------##
    case ActionsTypes.GET_COMPTE_RESULTAT_BILANS:
      return {
        ...state,
        isLoading: true,
        error: null,
        compteResultatBilans: [],
      };
    case ActionsTypes.GET_COMPTE_RESULTAT_BILANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        compteResultatBilans: action.response.data.data,
      };
    case ActionsTypes.GET_COMPTE_RESULTAT_BILANS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        compteResultatBilans: [],
      };
    // ##-----------END_GET_COMPTE_RESULTAT_BILANS-----------##

    // ##-----------ADD_COMPTE_RESULTAT_BILAN-----------##
    case ActionsTypes.ADD_COMPTE_RESULTAT_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //compteResultatBilans: [...state.compteResultatBilans],
      };

    case ActionsTypes.ADD_COMPTE_RESULTAT_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //compteResultatBilans: [...state.compteResultatBilans, action.response.data.data],
      };

    case ActionsTypes.ADD_COMPTE_RESULTAT_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //compteResultatBilans: [...state.compteResultatBilans],
      };
    // ##-----------END_ADD_COMPTE_RESULTAT_BILAN-----------##

    // ##-----------EDIT_COMPTE_RESULTAT_BILAN-----------##
    case ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        compteResultatBilan: {},
      };

    case ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        compteResultatBilan: action.response.data.data,
      };

    case ActionsTypes.EDIT_COMPTE_RESULTAT_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        compteResultatBilan: {},
      };
    // ##-----------END_EDIT_COMPTE_RESULTAT_BILAN-----------##

    // ##-----------UPDATE_COMPTE_RESULTAT_BILAN-----------##
    case ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //compteResultatBilans: [...state.compteResultatBilans],
      };

    case ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // compteResultatBilans: [
        //   action.response.data.data,
        //   ...state.compteResultatBilans.filter(
        //     (compteResultatBilan) => compteResultatBilan.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_COMPTE_RESULTAT_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //compteResultatBilans: [...state.compteResultatBilans],
      };
      // ##-----------END_UPDATE_COMPTE_RESULTAT_BILAN-----------##
  
      case ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN:
        return {
          ...state,
          isLoading: true,
          error: null,
          //compteResultatBilans: [...state.compteResultatBilans],
        };
  
      case ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
          // compteResultatBilans: [
          //   ...state.compteResultatBilans.filter(
          //     (compteResultatBilan) => compteResultatBilan.id != action.response.data.data
          //   ),
          // ],
        };
  
      case ActionsTypes.DELETE_COMPTE_RESULTAT_BILAN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
          //compteResultatBilans: [...state.compteResultatBilans],
        };
      // ##-----------END_DELETE_COMPTE_RESULTAT_BILAN-----------##
    // ##-----------END_UPDATE_COMPTE_RESULTAT_BILAN-----------##

    case ActionsTypes.UPLOAD_COMPTE_RESULTAT_BILANS:
      return {
        ...state,
        isLoading: true,
        error: null,
        //compteResultatBilans: [...state.compteResultatBilans],
      };

    case ActionsTypes.UPLOAD_COMPTE_RESULTAT_BILANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // compteResultatBilans: [
        //   ...state.compteResultatBilans.filter(
        //     (compteResultatBilan) => compteResultatBilan.id != action.response.data.data
        //   ),
        // ],
      };

    case ActionsTypes.UPLOAD_COMPTE_RESULTAT_BILANS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //compteResultatBilans: [...state.compteResultatBilans],
      };
    // ##-----------END_DELETE_COMPTE_RESULTAT_BILAN-----------##

    default:
      return state;
  }
};

export default compteResultatBilansReducer;
