import React, { useEffect } from "react";
import forbiddenImage from "../assets/images/forbidden.jpg";
import lockImage from "../assets/images/lock.png";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialUIController, setLayout } from "context";
import { styled } from "@mui/material/styles";
import { height } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import MDButton from "components/MDButton";
// import { withRouter } from "react-router";

function ForbiddenComponent() {
  // Material Dashboard 2 React context

  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  const navigate = useNavigate();
  const goBackHome = () => {
    localStorage.clear();
    navigate("/authentication/sign-in");
  };

  const ForbiddenContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "95%",
      position: "relative",
      margin: "auto",
      top: "20%",
      // margin:"auto",
      alignItems: "left",
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      position: "relative",
      // left: "25%",
      top: "20%",
      margin: "auto",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      position: "relative",
      // left: "25%",
      top: "20%",
      margin: "auto",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  }));

  return (
    <div
      style={{
        // padding: "100px 0",
        background: "white",
        height: "100vh",
      }}
    >
      <ForbiddenContainer
        py={5}
        // sx={{

        // }}
      >
        <img
          width="25%"
          height="fit-content"
          // style={{ selfAlign: "center" }}
          src={lockImage}
          alt="forbidden"
        />
        <Typography variant="h1" my={1} fontWeight="light">
          Accès Refusé/Interdit
        </Typography>
        <Typography variant="body1" color={grey[600]} mb={4} fontWeight="light">
          La page que vous essayez d'atteindre est absolument interdite pour une raison quelconque
        </Typography>
        <MDButton variant="contained" color="info" my={4} onClick={goBackHome}>
          Retourner
        </MDButton>
      </ForbiddenContainer>
    </div>
  );
}
export default ForbiddenComponent;
