import * as Yup from "yup";

export const addCompteResultatBilanSchema = Yup.object().shape({
  total_production_exploitation: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  chiffre_affaire_net: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  cout_vente: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  marge_brut: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_charge_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  produit_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  charge_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_avant_impot: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  impot_taxe_assimitee: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_apres_imposition: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  resultat_exceptionnel: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  change_exceptionnelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  benefice_pert: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  exceptional_products: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
});

export const updateCompteResultatBilanSchema = Yup.object().shape({
  total_production_exploitation: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  chiffre_affaire_net: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  cout_vente: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  marge_brut: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  autre_charge_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_exploitation: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  produit_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  charge_financier: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_avant_impot: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  impot_taxe_assimitee: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  resultat_courant_apres_imposition: Yup.number("La valeur doit être un nombre").required(
    "Obligatoire"
  ),
  resultat_exceptionnel: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  change_exceptionnelle: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  benefice_pert: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
  date: Yup.date().required("Obligatoire"),
  company_id: Yup.number("La valeur doit être un nombre").integer().required("Obligatoire"),
  exceptional_products: Yup.number("La valeur doit être un nombre").required("Obligatoire"),
});
