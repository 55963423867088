import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/FinancialProfilesAction";
import {
  addFinancialProfilesApi,
  deleteFinancialProfileApi,
  editFinancialProfileApi,
  getFinancialProfilesApi,
  updateFinancialProfileApi,
  uploadFinancialProfileApi,
} from "../../../../common/services/Apis/financialProfilesApi";

export function* getFinancialProfilesSaga(action) {
  try {
    const response = yield call(getFinancialProfilesApi, action.payload);
    yield put(actions.getFinancialProfilesSuccess(response));
  } catch (error) {
    yield put(actions.getFinancialProfilesFail(error));
  }
}

export function* addFinancialProfileSaga(action) {
  try {
    const response = yield call(addFinancialProfilesApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addFinancialProfileSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addFinancialProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editFinancialProfileSaga(action) {
  try {
    const response = yield call(editFinancialProfileApi, action.payload);
    yield put(actions.editFinancialProfileSuccess(response));
  } catch (error) {
    yield put(actions.editFinancialProfileFail(error));
  }
}

export function* updateFinancialProfileSaga(action) {
  try {
    const response = yield call(updateFinancialProfileApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateFinancialProfileSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateFinancialProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteFinancialProfileSaga(action) {
  try {
    const response = yield call(deleteFinancialProfileApi, action?.payload?.payload);
    yield put(actions.deleteFinancialProfileSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteFinancialProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* uploadFinancialProfileSaga(action) {
  try {
    const response = yield call(uploadFinancialProfileApi, action?.payload?.payload);
    yield put(actions.uploadFinancialProfileSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadFinancialProfileFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
