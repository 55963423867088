import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/MemoBilansAction";
import {
  getMemoBilansApi,
  addMemoBilanApi,
  editMemoBilanApi,
  updateMemoBilanApi,
  deleteMemoBilanApi,
  uploadMemoBilanApi
} from "common/services/Apis/memoBilansApi";

export function* getMemoBilansSaga(action) {
  try {
    const response = yield call(getMemoBilansApi, action.payload);
    yield put(actions.getMemoBilansSuccess(response));
  } catch (error) {
    yield put(actions.getMemoBilansFail(error));
  }
}

export function* addMemoBilanSaga(action) {
  try {
    const response = yield call(addMemoBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addMemoBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addMemoBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editMemoBilanSaga(action) {
  try {
    const response = yield call(editMemoBilanApi, action.payload);
    yield put(actions.editMemoBilanSuccess(response));
  } catch (error) {
    yield put(actions.editMemoBilanFail(error));
  }
}

export function* updateMemoBilanSaga(action) {
  try {
    const response = yield call(updateMemoBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateMemoBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateMemoBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteMemoBilanSaga(action) {
  try {
    const response = yield call(deleteMemoBilanApi, action?.payload?.payload);
    yield put(actions.deleteMemoBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteMemoBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* uploadMemoBilanSaga(action) {
  try {
    const response = yield call(uploadMemoBilanApi, action?.payload?.payload);
    yield put(actions.uploadMemoBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadMemoBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
