import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";
import {
  addMemoBilanSaga,
  deleteMemoBilanSaga,
  editMemoBilanSaga,
  getMemoBilansSaga,
  updateMemoBilanSaga,
  uploadMemoBilanSaga,
} from "./MemoBilansSaga";

export default function* MemoBilansWatcher() {
  yield takeLatest(ActionsTypes.GET_MEMO_BILANS, getMemoBilansSaga);
  yield takeLatest(ActionsTypes.ADD_MEMO_BILAN, addMemoBilanSaga);
  yield takeLatest(ActionsTypes.EDIT_MEMO_BILAN, editMemoBilanSaga);
  yield takeLatest(ActionsTypes.UPDATE_MEMO_BILAN, updateMemoBilanSaga);
  yield takeLatest(ActionsTypes.DELETE_MEMO_BILAN, deleteMemoBilanSaga);
  yield takeLatest(ActionsTypes.UPLOAD_MEMO_BILAN, uploadMemoBilanSaga);
}
