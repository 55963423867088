import { instance } from "../../utils/Axios";

export const getFinancialProfilesApi = (data) => {
  return instance.get(`/financialProfiles?company_id=${data?.companyId ?? ""}`).then((response) => {
    return response;
  });
};

export const addFinancialProfilesApi = (data) => {
  return instance.post("/financialProfiles", data).then((response) => {
    return response;
  });
};

export const editFinancialProfileApi = (id) => {
  return instance.get(`/financialProfiles/${id}`).then((response) => {
    return response;
  });
};

export const updateFinancialProfileApi = (data) => {
  return instance.put(`/financialProfiles/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteFinancialProfileApi = (data) => {
  return instance.delete(`/financialProfiles/${data.id}`).then((response) => {
    return response;
  });
};





export const uploadFinancialProfileApi = (data) => {
  return instance
    .post("/financialProfiles/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};