import * as ActionsTypes from "../../../../common/state/StatesConstants";

// ##-----------GET_COMPANIES-----------##
export const getCompanies = (data = {}) => {
  return { type: ActionsTypes.GET_COMPANIES, payload: data };
};

export const getCompaniesSuccess = (response) => {
  return { type: ActionsTypes.GET_COMPANIES_SUCCESS, response };
};

export const getCompaniesFail = (error) => {
  return { type: ActionsTypes.GET_COMPANIES_FAIL, error };
};
// ##-----------END_GET_COMPANIES-----------##

// ##-----------ADD_COMPANY-----------##
export const addCompany = (data) => {
  return { type: ActionsTypes.ADD_COMPANY, payload: data };
};

export const addCompanySuccess = (response) => {
  return { type: ActionsTypes.ADD_COMPANY_SUCCESS, response };
};

export const addCompanyFail = (error) => {
  return { type: ActionsTypes.ADD_COMPANY_FAIL, error };
};
// ##-----------END_ADD_COMPANY-----------##

// ##-----------EDIT_COMPANY-----------##
export const editCompany = (data) => {
  return { type: ActionsTypes.EDIT_COMPANY, payload: data };
};

export const editCompanySuccess = (response) => {
  return { type: ActionsTypes.EDIT_COMPANY_SUCCESS, response };
};

export const editCompanyFail = (error) => {
  return { type: ActionsTypes.EDIT_COMPANY_FAIL, error };
};
// ##-----------END_EDIT_COMPANY-----------##

// ##-----------UPDATE_COMPANY-----------##
export const updateCompany = (data) => {
  return { type: ActionsTypes.UPDATE_COMPANY, payload: data };
};

export const updateCompanySuccess = (response) => {
  return { type: ActionsTypes.UPDATE_COMPANY_SUCCESS, response };
};

export const updateCompanyFail = (error) => {
  return { type: ActionsTypes.UPDATE_COMPANY_FAIL, error };
};
// ##-----------END_UPDATE_COMPANY-----------##

// ##-----------DELETE_COMPANY-----------##
export const deleteCompany = (data) => {
  return { type: ActionsTypes.DELETE_COMPANY, payload: data };
};

export const deleteCompanySuccess = (response) => {
  return { type: ActionsTypes.DELETE_COMPANY_SUCCESS, response };
};

export const deleteCompanyFail = (error) => {
  return { type: ActionsTypes.DELETE_COMPANY_FAIL, error };
};
// ##-----------END_DELETE_COMPANY-----------##


// ##-----------UPLOAD_COMPANIES-----------##
export const uploadCompany = (data) => {
  
  console.log("data;;;;",data)
  return { type: ActionsTypes.UPLOAD_COMPANY, payload: data };
};

export const uploadCompanySuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_COMPANY_SUCCESS, response };
};

export const uploadCompanyFail = (error) => {
  return { type: ActionsTypes.UPLOAD_COMPANY_FAIL, error };
};
// ##-----------DOWNLOAD_RAPPORT-----------##
export const getRapport = (data) => {
  return { type: ActionsTypes.DOWNLOAD_RAPPORT, payload: data };
};

