import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CompteDeResultatBilansAction";
import {
  getCompteDeResultatBilansApi,
  addCompteDeResultatBilanApi,
  editCompteDeResultatBilanApi,
  updateCompteDeResultatBilanApi,
  deleteCompteDeResultatBilanApi,
  uploadCompteDeResultatBilanApi
} from "common/services/Apis/compteDeResultatBilansApi";

export function* getCompteDeResultatBilansSaga(action) {
  try {
    const response = yield call(getCompteDeResultatBilansApi, action.payload);
    yield put(actions.getCompteDeResultatBilansSuccess(response));
  } catch (error) {
    yield put(actions.getCompteDeResultatBilansFail(error));
  }
}

export function* addCompteDeResultatBilanSaga(action) {
  try {
    const response = yield call(addCompteDeResultatBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addCompteDeResultatBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addCompteDeResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editCompteDeResultatBilanSaga(action) {
  try {
    const response = yield call(editCompteDeResultatBilanApi, action.payload);
    yield put(actions.editCompteDeResultatBilanSuccess(response));
  } catch (error) {
    yield put(actions.editCompteDeResultatBilanFail(error));
  }
}

export function* updateCompteDeResultatBilanSaga(action) {
  try {
    const response = yield call(updateCompteDeResultatBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateCompteDeResultatBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateCompteDeResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteCompteDeResultatBilanSaga(action) {
  try {
    const response = yield call(deleteCompteDeResultatBilanApi, action?.payload?.payload);
    yield put(actions.deleteCompteDeResultatBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteCompteDeResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* uploadCompteDeResultatBilanSaga(action) {
  try {
    const response = yield call(uploadCompteDeResultatBilanApi, action?.payload?.payload);
    yield put(actions.uploadCompteDeResultatBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadCompteDeResultatBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
