import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------GET_COMPTE_DE_RESULTAT_BILANS-----------##
export const getCompteDeResultatBilans = (data) => {
  console.log("getCompteDeResultatBilans", data);
  return { type: ActionsTypes.GET_COMPTE_DE_RESULTAT_BILANS, payload: data };
};

export const getCompteDeResultatBilansSuccess = (response) => {
  return { type: ActionsTypes.GET_COMPTE_DE_RESULTAT_BILANS_SUCCESS, response };
};

export const getCompteDeResultatBilansFail = (error) => {
  return { type: ActionsTypes.GET_COMPTE_DE_RESULTAT_BILANS_FAIL, error };
};
// ##-----------END_GET_COMPTE_DE_RESULTAT_BILANS-----------##

// ##-----------ADD_COMPTE_DE_RESULTAT_BILAN-----------##

export const addCompteDeResultatBilan = (data) => {
  return { type: ActionsTypes.ADD_COMPTE_DE_RESULTAT_BILAN, payload: data };
};

export const addCompteDeResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.ADD_COMPTE_DE_RESULTAT_BILAN_SUCCESS, response };
};

export const addCompteDeResultatBilanFail = (error) => {
  return { type: ActionsTypes.ADD_COMPTE_DE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_ADD_COMPTE_DE_RESULTAT_BILAN-----------##

// ##-----------EDIT_COMPTE_DE_RESULTAT_BILAN-----------##
export const editCompteDeResultatBilan = (data) => {
  return { type: ActionsTypes.EDIT_COMPTE_DE_RESULTAT_BILAN, payload: data };
};

export const editCompteDeResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.EDIT_COMPTE_DE_RESULTAT_BILAN_SUCCESS, response };
};

export const editCompteDeResultatBilanFail = (error) => {
  return { type: ActionsTypes.EDIT_COMPTE_DE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_EDIT_COMPTE_DE_RESULTAT_BILAN-----------##

// ##-----------UPDATE_COMPTE_DE_RESULTAT_BILAN-----------##
export const updateCompteDeResultatBilan = (data) => {
  return { type: ActionsTypes.UPDATE_COMPTE_DE_RESULTAT_BILAN, payload: data };
};

export const updateCompteDeResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_COMPTE_DE_RESULTAT_BILAN_SUCCESS, response };
};

export const updateCompteDeResultatBilanFail = (error) => {
  return { type: ActionsTypes.UPDATE_COMPTE_DE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_UPDATE_COMPTE_DE_RESULTAT_BILAN-----------##

// ##-----------DELETE_COMPTE_DE_RESULTAT_BILAN-----------##
export const deleteCompteDeResultatBilan = (data) => {
  return { type: ActionsTypes.DELETE_COMPTE_DE_RESULTAT_BILAN, payload: data };
};

export const deleteCompteDeResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.DELETE_COMPTE_DE_RESULTAT_BILAN_SUCCESS, response };
};

export const deleteCompteDeResultatBilanFail = (error) => {
  return { type: ActionsTypes.DELETE_COMPTE_DE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_DELETE_COMPTE_DE_RESULTAT_BILAN-----------##

// ##-----------UPLOAD_COMPTE_DE_RESULTAT_BILAN-----------##
export const uploadCompteDeResultatBilan = (data) => {
  return { type: ActionsTypes.UPLOAD_COMPTE_DE_RESULTAT_BILAN, payload: data };
};

export const uploadCompteDeResultatBilanSuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_COMPTE_DE_RESULTAT_BILAN_SUCCESS, response };
};

export const uploadCompteDeResultatBilanFail = (error) => {
  return { type: ActionsTypes.UPLOAD_COMPTE_DE_RESULTAT_BILAN_FAIL, error };
};
// ##-----------END_UPLOAD_COMPTE_DE_RESULTAT_BILAN-----------##
