import { instance } from "../../utils/Axios";

export const getCompteResultatBilansApi = (data) => {
  return instance
    .get(
      `/compteResultatBilans?company_id=${data?.companyId??''}&sort=${data?.sort??''}&order=${data?.order??''}`
    )
    .then((response) => {
      return response;
    });
};

export const addCompteResultatBilanApi = (data) => {
  return instance.post("/compteResultatBilans", data).then((response) => {
    return response;
  });
};

export const editCompteResultatBilanApi = (id) => {
  return instance.get(`/compteResultatBilans/${id}`).then((response) => {
    return response;
  });
};

export const updateCompteResultatBilanApi = (data) => {
  return instance.put(`/compteResultatBilans/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteCompteResultatBilanApi = (data) => {
  return instance.delete(`/compteResultatBilans/${data.id}`).then((response) => {
    return response;
  });
};

export const uploadCompteResultatBilanApi = (data) => {
  return instance
    .post("/compteResultatBilans/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};
