import { instance } from "../../utils/Axios";

export const getActifBilansApi = (data = {}) => {
  return instance
    .get(
      `/actifBilans?company_id=${data.companyId ?? ""}&sort=${data.sort ?? ""}&order=${
        data.order ?? ""
      }`,
      data
    )
    .then((response) => {
      return response;
    });
};
export const addActifBilanApi = (data) => {
  return instance.post("/actifBilans", data).then((response) => {
    return response;
  });
};

export const editActifBilanApi = (id) => {
  return instance.get(`/actifBilans/${id}`).then((response) => {
    return response;
  });
};

export const updateActifBilanApi = (data) => {
  return instance.put(`/actifBilans/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteActifBilanApi = (data) => {
  console.log("delete data", data);
  return instance.delete(`/actifBilans/${data.id}`).then((response) => {
    return response;
  });
};



export const uploadActifBilanApi = (data) => {
  return instance
    .post("/actifBilans/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};
