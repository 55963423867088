import { instance } from "../../utils/Axios";

export const getMemoBilansApi = (data) => {
  return instance
    .get(
      `/memoBilans?company_id=${data?.companyId??''}&sort=${data?.sort??''}&order=${data?.order??''}`
    )
    .then((response) => {
      return response;
    });
};

export const addMemoBilanApi = (data) => {
  return instance.post("/memoBilans", data).then((response) => {
    return response;
  });
};

export const editMemoBilanApi = (id) => {
  return instance.get(`/memoBilans/${id}`).then((response) => {
    return response;
  });
};

export const updateMemoBilanApi = (data) => {
  return instance.put(`/memoBilans/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteMemoBilanApi = (data) => {
  return instance.delete(`/memoBilans/${data.id}`).then((response) => {
    return response;
  });
};




export const uploadMemoBilanApi = (data) => {
  return instance
    .post("/memoBilans/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};
