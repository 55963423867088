import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

// ##-----------GET_ACTIF_BILANS-----------##
export const getActifBilans = (data) => {
  console.log("getActifBilans", data);
  return { type: ActionsTypes.GET_ACTIF_BILANS, payload: data };
};

export const getActifBilansSuccess = (response) => {
  return { type: ActionsTypes.GET_ACTIF_BILANS_SUCCESS, response };
};

export const getActifBilansFail = (error) => {
  return { type: ActionsTypes.GET_ACTIF_BILANS_FAIL, error };
};
// ##-----------END_GET_ACTIF_BILANS-----------##

// ##-----------ADD_ACTIF_BILAN-----------##

export const addActifBilan = (data) => {
  return { type: ActionsTypes.ADD_ACTIF_BILAN, payload: data };
};

export const addActifBilanSuccess = (response) => {
  return { type: ActionsTypes.ADD_ACTIF_BILAN_SUCCESS, response };
};

export const addActifBilanFail = (error) => {
  return { type: ActionsTypes.ADD_ACTIF_BILAN_FAIL, error };
};
// ##-----------END_ADD_ACTIF_BILAN-----------##

// ##-----------EDIT_ACTIF_BILAN-----------##
export const editActifBilan = (data) => {
  return { type: ActionsTypes.EDIT_ACTIF_BILAN, payload: data };
};

export const editActifBilanSuccess = (response) => {
  return { type: ActionsTypes.EDIT_ACTIF_BILAN_SUCCESS, response };
};

export const editActifBilanFail = (error) => {
  return { type: ActionsTypes.EDIT_ACTIF_BILAN_FAIL, error };
};
// ##-----------END_EDIT_ACTIF_BILAN-----------##

// ##-----------UPDATE_ACTIF_BILAN-----------##
export const updateActifBilan = (data) => {
  return { type: ActionsTypes.UPDATE_ACTIF_BILAN, payload: data };
};

export const updateActifBilanSuccess = (response) => {
  return { type: ActionsTypes.UPDATE_ACTIF_BILAN_SUCCESS, response };
};

export const updateActifBilanFail = (error) => {
  return { type: ActionsTypes.UPDATE_ACTIF_BILAN_FAIL, error };
};
// ##-----------END_UPDATE_ACTIF_BILAN-----------##

// ##-----------DELETE_ACTIF_BILAN-----------##
export const deleteActifBilan = (data) => {
  return { type: ActionsTypes.DELETE_ACTIF_BILAN, payload: data };
};

export const deleteActifBilanSuccess = (response) => {
  return { type: ActionsTypes.DELETE_ACTIF_BILAN_SUCCESS, response };
};

export const deleteActifBilanFail = (error) => {
  return { type: ActionsTypes.DELETE_ACTIF_BILAN_FAIL, error };
};
// ##-----------END_DELETE_ACTIF_BILAN-----------##

// ##-----------UPLOAD_ACTIF_BILAN-----------##
export const uploadActifBilan = (data) => {
  return { type: ActionsTypes.UPLOAD_ACTIF_BILAN, payload: data };
};

export const uploadActifBilanSuccess = (response) => {
  return { type: ActionsTypes.UPLOAD_ACTIF_BILAN_SUCCESS, response };
};

export const uploadActifBilanFail = (error) => {
  return { type: ActionsTypes.UPLOAD_ACTIF_BILAN_FAIL, error };
};
// ##-----------END_UPLOAD_ACTIF_BILAN-----------##
