import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/ActifBilansAction";
import {
  getActifBilansApi,
  addActifBilanApi,
  editActifBilanApi,
  updateActifBilanApi,
  deleteActifBilanApi,
  uploadActifBilanApi,
} from "common/services/Apis/actifBilansApi";

export function* getActifBilansSaga(action) {
  try {
    const response = yield call(getActifBilansApi, action.payload);
    yield put(actions.getActifBilansSuccess(response));
  } catch (error) {
    yield put(actions.getActifBilansFail(error));
  }
}

export function* addActifBilanSaga(action) {
  try {
    const response = yield call(addActifBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addActifBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addActifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* editActifBilanSaga(action) {
  try {
    const response = yield call(editActifBilanApi, action.payload);
    yield put(actions.editActifBilanSuccess(response));
  } catch (error) {
    yield put(actions.editActifBilanFail(error));
  }
}

export function* updateActifBilanSaga(action) {
  try {
    const response = yield call(updateActifBilanApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateActifBilanSuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateActifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteActifBilanSaga(action) {
  try {
    const response = yield call(deleteActifBilanApi, action?.payload?.payload);
    yield put(actions.deleteActifBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteActifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* uploadActifBilanSaga(action) {
  try {
    const response = yield call(uploadActifBilanApi, action?.payload?.payload);
    yield put(actions.uploadActifBilanSuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadActifBilanFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
