import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addFinancialProfileSaga,
  deleteFinancialProfileSaga,
  editFinancialProfileSaga,
  getFinancialProfilesSaga,
  updateFinancialProfileSaga,
  uploadFinancialProfileSaga,
} from "./FinancialProfilesSaga";

export default function* FinancialProfilesWatcher() {
  yield takeLatest(ActionsTypes.GET_FINANCIAL_PROFILES, getFinancialProfilesSaga);
  yield takeLatest(ActionsTypes.ADD_FINANCIAL_PROFILE, addFinancialProfileSaga);
  yield takeLatest(ActionsTypes.EDIT_FINANCIAL_PROFILE, editFinancialProfileSaga);
  yield takeLatest(ActionsTypes.UPDATE_FINANCIAL_PROFILE, updateFinancialProfileSaga);
  yield takeLatest(ActionsTypes.DELETE_FINANCIAL_PROFILE, deleteFinancialProfileSaga);
  yield takeLatest(ActionsTypes.UPLOAD_FINANCIAL_PROFILE, uploadFinancialProfileSaga);
}
