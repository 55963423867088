import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../../../common/state/StatesConstants";
import {
  addCompteDeResultatBilanSaga,
  deleteCompteDeResultatBilanSaga,
  editCompteDeResultatBilanSaga,
  getCompteDeResultatBilansSaga,
  updateCompteDeResultatBilanSaga,
  uploadCompteDeResultatBilanSaga,
} from "./CompteDeResultatBilansSaga";

export default function* CompteDeResultatBilansWatcher() {
  yield takeLatest(ActionsTypes.GET_COMPTE_DE_RESULTAT_BILANS, getCompteDeResultatBilansSaga);
  yield takeLatest(ActionsTypes.ADD_COMPTE_DE_RESULTAT_BILAN, addCompteDeResultatBilanSaga);
  yield takeLatest(ActionsTypes.EDIT_COMPTE_DE_RESULTAT_BILAN, editCompteDeResultatBilanSaga);
  yield takeLatest(ActionsTypes.UPDATE_COMPTE_DE_RESULTAT_BILAN, updateCompteDeResultatBilanSaga);
  yield takeLatest(ActionsTypes.DELETE_COMPTE_DE_RESULTAT_BILAN, deleteCompteDeResultatBilanSaga);
  yield takeLatest(ActionsTypes.UPLOAD_COMPTE_DE_RESULTAT_BILAN, uploadCompteDeResultatBilanSaga);
}
