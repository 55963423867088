import { instance } from "../../utils/Axios";

export const getCompteDeResultatBilansApi = (data = {}) => {
  return instance
    .get(
      `/compteDeResultatBilans?company_id=${data.companyId ?? ""}&sort=${data.sort ?? ""}&order=${
        data.order ?? ""
      }`,
      data
    )
    .then((response) => {
      return response;
    });
};
export const addCompteDeResultatBilanApi = (data) => {
  return instance.post("/compteDeResultatBilans", data).then((response) => {
    return response;
  });
};

export const editCompteDeResultatBilanApi = (id) => {
  return instance.get(`/compteDeResultatBilans/${id}`).then((response) => {
    return response;
  });
};

export const updateCompteDeResultatBilanApi = (data) => {
  return instance.put(`/compteDeResultatBilans/${data.id}`, data).then((response) => {
    return response;
  });
};

export const deleteCompteDeResultatBilanApi = (data) => {
  console.log("delete data", data);
  return instance.delete(`/compteDeResultatBilans/${data.id}`).then((response) => {
    return response;
  });
};



export const uploadCompteDeResultatBilanApi = (data) => {
  return instance
    .post("/compteDeResultatBilans/import-excel", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};