import jwt from "jwt-decode";

class JwtUtils {
  getEmail() {
    return jwt(localStorage.access_token).sub;
  }

  isActif() {
    // pour verifier le Token if  existe  merci de decommanter  le  code  cidessous
    if (localStorage.access_token) {
      return jwt(localStorage.access_token)?.exp > (new Date().getTime() + 1) / 1000;
    }
    // dans  leta  everification merci de commanter  le code  cidessou
    return false;
  }

  hasRole(roleIn) {
    if (!localStorage.access_token) {
      return false;
    }
    let role = jwt(localStorage.access_token)?.role;
    console.log(role)
    if (role) {
      // for (let role in roles) {
        console.log(role)
        console.log(roleIn)
      if (role === roleIn) {
        return true;
        }
      // }
    }
    return false;
  }

  hasAnyRole(rolesIn) {
    if (rolesIn?.length === 0) {
      return true;
    }

    console.log(rolesIn)
    for (const roleIn in rolesIn) {
      console.log(rolesIn[roleIn])
      if (this.hasRole(rolesIn[roleIn])) {
        return true;
      }
    }
    return false;
  }

  logOutWithoutRefresh() {
    localStorage.token && localStorage.removeItem("access_token");
  }

  logOut() {
    if (localStorage.token) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  }
}

export default new JwtUtils();
