import { useEffect } from "react";
import * as ActionsTypes from "../../../../../../common/state/StatesConstants";

const initialState = {
  error: null,
  isLoading: false,
  actifBilans: [],
  actifBilan: {},
};

const actifBilansReducer = (state = initialState, action) => {
  switch (action.type) {
    // ##-----------GET_ACTIF_BILANS-----------##
    case ActionsTypes.GET_ACTIF_BILANS:
      return {
        ...state,
        isLoading: true,
        error: null,
        actifBilans: [],
      };
    case ActionsTypes.GET_ACTIF_BILANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        actifBilans: action.response.data.data,
      };
    case ActionsTypes.GET_ACTIF_BILANS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        actifBilans: [],
      };
    // ##-----------END_GET_ACTIF_BILANS-----------##

    // ##-----------ADD_ACTIF_BILAN-----------##
    case ActionsTypes.ADD_ACTIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //actifBilans: [...state.actifBilans],
      };

    case ActionsTypes.ADD_ACTIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        //actifBilans: [...state.actifBilans, action.response.data.data],
      };

    case ActionsTypes.ADD_ACTIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //actifBilans: [...state.actifBilans],
      };
    // ##-----------END_ADD_ACTIF_BILAN-----------##

    // ##-----------EDIT_ACTIF_BILAN-----------##
    case ActionsTypes.EDIT_ACTIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        actifBilan: {},
      };

    case ActionsTypes.EDIT_ACTIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        actifBilan: action.response.data.data,
      };

    case ActionsTypes.EDIT_ACTIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        actifBilan: {},
      };
    // ##-----------END_EDIT_ACTIF_BILAN-----------##

    // ##-----------UPDATE_ACTIF_BILAN-----------##
    case ActionsTypes.UPDATE_ACTIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //actifBilans: [...state.actifBilans],
      };

    case ActionsTypes.UPDATE_ACTIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // actifBilans: [
        //   action.response.data.data,
        //   ...state.actifBilans.filter(
        //     (actifBilan) => actifBilan.id != action.response.data.data.id
        //   ),
        // ],
      };

    case ActionsTypes.UPDATE_ACTIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //actifBilans: [...state.actifBilans],
      };
      // ##-----------END_UPDATE_ACTIF_BILAN-----------##
  
      case ActionsTypes.DELETE_ACTIF_BILAN:
        return {
          ...state,
          isLoading: true,
          error: null,
          //actifBilans: [...state.actifBilans],
        };
  
      case ActionsTypes.DELETE_ACTIF_BILAN_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
          // actifBilans: [
          //   ...state.actifBilans.filter(
          //     (actifBilan) => actifBilan.id != action.response.data.data
          //   ),
          // ],
        };
  
      case ActionsTypes.DELETE_ACTIF_BILAN_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.error,
          //actifBilans: [...state.actifBilans],
        };
      // ##-----------END_DELETE_ACTIF_BILAN-----------##
    // ##-----------END_UPDATE_ACTIF_BILAN-----------##

    case ActionsTypes.UPLOAD_ACTIF_BILAN:
      return {
        ...state,
        isLoading: true,
        error: null,
        //actifBilans: [...state.actifBilans],
      };

    case ActionsTypes.UPLOAD_ACTIF_BILAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        // actifBilans: [
        //   ...state.actifBilans.filter(
        //     (actifBilan) => actifBilan.id != action.response.data.data
        //   ),
        // ],
      };

    case ActionsTypes.UPLOAD_ACTIF_BILAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        //actifBilans: [...state.actifBilans],
      };
    // ##-----------END_UPLOAD_ACTIF_BILAN-----------##

    default:
      return state;
  }
};

export default actifBilansReducer;
