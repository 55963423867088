import { takeLatest } from "redux-saga/effects";

import * as ActionsTypes from "../../../../common/state/StatesConstants";
import {
  addCountryProfileSaga,
  deleteCountryProfileSaga,
  editCountryProfileSaga,
  getCountryProfilesSaga,
  updateCountryProfileSaga,
  uploadCountryProfileSaga,
} from "./CountryProfilesSaga";

export default function* CountryProfilesWatcher() {
  yield takeLatest(ActionsTypes.GET_COUNTRY_PROFILES, getCountryProfilesSaga);
  yield takeLatest(ActionsTypes.ADD_COUNTRY_PROFILE, addCountryProfileSaga);
  yield takeLatest(ActionsTypes.EDIT_COUNTRY_PROFILE, editCountryProfileSaga);
  yield takeLatest(ActionsTypes.UPDATE_COUNTRY_PROFILE, updateCountryProfileSaga);
  yield takeLatest(ActionsTypes.DELETE_COUNTRY_PROFILE, deleteCountryProfileSaga);
  yield takeLatest(ActionsTypes.UPLOAD_COUNTRY_PROFILE, uploadCountryProfileSaga);
}
