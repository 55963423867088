import { put, call, delay } from "redux-saga/effects";
import * as actions from "../state/CompaniesAction";
import {
  getCompaniesApi,
  addCompanyApi,
  editCompanyApi,
  updateCompanyApi,
  deleteCompanyApi,
  uploadCompanyApi,
  getRapportApi
} from "../../../../common/services/Apis/companiesApi";

export function* getCompaniesSaga(data = {}) {
  try {
    const response = yield call(getCompaniesApi, data?.payload);
    yield put(actions.getCompaniesSuccess(response));
  } catch (error) {
    yield put(actions.getCompaniesFail(error));
  }
}
export function* addCompanySaga(action) {
  console.log("action", action);
  try {
    const response = yield call(addCompanyApi, action?.payload?.payload);
    if (response) {
      yield put(actions.addCompanySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.addCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}
export function* editCompanySaga(action) {
  try {
    const response = yield call(editCompanyApi, action.payload);
    yield put(actions.editCompanySuccess(response));
  } catch (error) {
    yield put(actions.editCompanyFail(error));
  }
}

export function* updateCompanySaga(action) {
  try {
    const response = yield call(updateCompanyApi, action?.payload?.payload);
    if (response) {
      yield put(actions.updateCompanySuccess(response));
      yield call(action.payload.successCallBack, response);
    }
  } catch (error) {
    yield put(actions.updateCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* deleteCompanySaga(action) {
  try {
    const response = yield call(deleteCompanyApi, action?.payload?.payload);
    yield put(actions.deleteCompanySuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.deleteCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}



export function* uploadCompanySaga(action) {
  
  console.log("uploadCompanySaga;;;;",action)
  try {
    const response = yield call(uploadCompanyApi, action?.payload?.payload);
    yield put(actions.uploadCompanySuccess(response));
    yield call(action.payload.successCallBack, response);
  } catch (error) {
    yield put(actions.uploadCompanyFail(error));
    yield call(action.payload.failCallBack, error);
  }
}

export function* getRapportSaga(action) {
  try {
    const response = yield call(getRapportApi, action.payload);
    yield put(actions.getRapport(response));
  } catch (error) {
  }
}
